import React from "react";

import { AlertContext, AlertProvider } from "./AlertProvider";

/**
 * Launches an alert dialog with the specified title, message and buttons (optional).
 * Inspired by React Native's Alert API: https://reactnative.dev/docs/alert
 */
const useAlert = () => {
  const { openAlert, setAlertOptions } = React.useContext(AlertContext);
  return { alert: openAlert, setAlertOptions };
};

export { AlertProvider };
export default useAlert;
