import { createActionCreator } from "deox";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "@APP/redux";
import { API } from "@APP/services";

export const setTokenLoading = createActionCreator(
  "@auth/SET_TOKEN_LOADING",
  (resolve) => (loading: boolean) => resolve(loading),
);

export const setTokenSuccess = createActionCreator(
  "@auth/SET_TOKEN_SUCCESS",
  (resolve) => (token: string) => resolve(token),
);

export const setTokenError = createActionCreator(
  "@auth/SET_TOKEN_ERROR",
  (resolve) => (isError: boolean) => resolve(isError),
);

export const loadAuthToken = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch(setTokenLoading(true));
  try {
    const token = await API.login();
    dispatch(setTokenSuccess(token));
    return token;
  } catch (err) {
    dispatch(setTokenError(true));
  }
};
