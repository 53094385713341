export const formatCurrency = ({
  amount = "0",
  currency = "GBP",
}: {
  amount?: string | number;
  currency?: string;
} = {}) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }).format(Number(amount));
  } catch {
    // Fallback to `currencyDisplay: "symbol"` when `narrowSymbol` is not supported:
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      currencyDisplay: "symbol",
    }).format(Number(amount));
  }
};

export const formatDateToUTCWithEndDay = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
  ).toISOString();
};
