import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

import { ButtonTypes } from "@APP/hooks/useAlert/AlertProvider";
import { history } from "@APP/navigation";
import { AppState } from "@APP/redux";

export enum BlockRequestStateEnum {
  success = "success",
  error = "error",
  loading = "loading",
}

export const getModalMessage = (
  state: BlockRequestStateEnum | null,
  rtpState: AppState["rtp"],
): React.ReactNode => {
  switch (state) {
    case BlockRequestStateEnum.success:
      return (
        <Typography color="textPrimary" align="center" id="declineRtpScreenSuccessText">
          You will no longer receive Payment Requests from{" "}
          <b>{rtpState.senderData?.businessContact?.name ?? rtpState.senderData?.name}</b>
        </Typography>
      );
    case BlockRequestStateEnum.error:
      return (
        <Typography color="textPrimary" align="center" id="declineRtpScreenErrorText">
          Oops, something seems to have gone wrong <br />
          It looks like there is a problem, please try again in a few moments
        </Typography>
      );
    case BlockRequestStateEnum.loading:
      return (
        <Box display="flex" height={100} justifyContent="center" id="declineRtpScreenLoadingText">
          <CircularProgress size={50} thickness={2.5} color="primary" />
        </Box>
      );
    default:
      return (
        <Typography color="textPrimary" align="center" id="declineRtpScreenDefaultText">
          Are you sure you want to decline this request for payment and block all future requests
          from <b>{rtpState.senderData?.businessContact?.name ?? rtpState.senderData?.name}</b>?
        </Typography>
      );
  }
};

export const getModalMessageCardPayments = (errorData?: any) => {
  return {
    component: (
      <Typography color="textPrimary" align="center" id="cardpaymentDeclineTypo">
        This payment has been declined by your card provider, please try again later. <br />
        {errorData?.errorCode ? (
          <>
            <small>Code - {errorData?.errorCode}</small>
          </>
        ) : (
          <></>
        )}
      </Typography>
    ),
  };
};

export const getModalCommonButton = () => {
  return [
    {
      text: "Close",
      type: ButtonTypes.back,
      closable: true,
    },
  ];
};

export const getModalButtons = (
  state: BlockRequestStateEnum | null,
  { handleBlockRequests }: { handleBlockRequests?: any },
) => {
  if (state === BlockRequestStateEnum.success || state === BlockRequestStateEnum.error) {
    return [
      {
        text: "Finish",
        type: state === BlockRequestStateEnum.success ? ButtonTypes.back : ButtonTypes.decline,
        onClick: () => history.push("/"),
        closable: true,
      },
    ];
  }

  if (state === BlockRequestStateEnum.loading) {
    return [];
  }

  return [
    {
      text: "Yes, block requests",
      type: ButtonTypes.decline,
      onClick: handleBlockRequests,
    },
    {
      text: "Cancel",
      type: ButtonTypes.back,
      closable: true,
    },
  ];
};
