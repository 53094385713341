/**
 * Keys for data stored in the Local Storage.
 */
export type AppStorageKey = "previousBank";

export const getItem = (key: AppStorageKey) => localStorage.getItem(key);
export const setItem = (key: AppStorageKey, value: string) => localStorage.setItem(key, value);
export const removeItem = (key: AppStorageKey) => localStorage.removeItem(key);

/**
 * A typesafe wrapper for the methods provided by browser's Local Storage API.
 * Only the keys listed in "AppStorageKey" can be used as arguments. to access the data in Local Storage.
 */
const AppLocalStorage = {
  getItem,
  setItem,
  removeItem,
};

export default AppLocalStorage;
