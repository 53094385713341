import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useFormik } from "formik";
import { Box, Button, Typography } from "@mui/material";
import * as H from "history";
import { isMobile } from "react-device-detect";

import { passwordValidationSchema } from "@APP/utils";
import { Message, PageLayout } from "@APP/components";
import { API } from "@APP/services";
import CONFIG from "@APP/config";
import { history } from "@APP/navigation";

import { validate } from "./resetPasswordSchema";
import PasswordField from "./PasswordField";

type FormInputData = { password: string };

const UNABLE_TO_REDIRECT = CONFIG.URLS.MOBILE?.LOGIN_URL && !isMobile;

type Props = {
  location?: H.Location;
  mode: "reset" | "setup";
};

const PasswordManagementView = ({ location, mode }: Props) => {
  const [loading, setLoading] = useState(true);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const queryParams = queryString.parse(location?.search ?? "");
  const { code } = queryParams;

  const isResetMode = mode === "reset";

  useEffect(() => {
    (async () => {
      if (!code) {
        setCodeInvalid(true);
        setLoading(false);
        return;
      }
      try {
        await API.verifyResetPasswordCode(code as string);
      } catch (err: any) {
        setCodeInvalid(true);
        const errorData = err?.response?.data;
        setErrorCode(errorData.errorCode || null);
      }
      setLoading(false);
    })();
  }, [code]);

  const { errors, handleBlur, handleChange, handleSubmit, touched, isValid, values } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: passwordValidationSchema,
    validate,
    onSubmit: async ({ password }: FormInputData) => {
      setLoading(true);
      setSubmitError("");
      try {
        if (isResetMode) {
          await API.resetPassword(code as string, password);
        } else {
          await API.setupPassword(code as string, password);
        }

        setFormSubmitted(true);
      } catch (err) {
        setSubmitError("Unable to set new password, please try again.");
      }
      setLoading(false);
    },
  });

  const finishButtonProps = {
    text: UNABLE_TO_REDIRECT && isResetMode ? "Okay" : "Go to Login Page",
    onClick: UNABLE_TO_REDIRECT && isResetMode ? () => history.replace("/") : undefined,
    href:
      UNABLE_TO_REDIRECT && isResetMode
        ? undefined
        : isMobile
        ? CONFIG.URLS.MOBILE?.LOGIN_URL
        : CONFIG.URLS.OCM.LOGIN_URL,
  };

  const renderMainContent = () => {
    if (codeInvalid) {
      return (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong"
          description={
            isResetMode
              ? "Your reset code is invalid or expired, please try again"
              : `Your registration link is invalid or expired, please try again. If the problem continues please contact your organisation administrator. ${
                  errorCode ? "<br><small>Code - " + errorCode + "</small>" : ""
                }`
          }
          buttons={[{ variant: "contained", color: "secondary", ...finishButtonProps }]}
        />
      );
    }

    if (formSubmitted) {
      return (
        <Message
          type="success"
          title={isResetMode ? "Password reset successful" : "Password is successfully saved"}
          description={
            !UNABLE_TO_REDIRECT && isResetMode
              ? `You can use your new password to log in to the ${
                  CONFIG.URLS.MOBILE?.LOGIN_URL ? " mobile" : ""
                } app.`
              : ""
          }
          buttons={[{ variant: "contained", color: "primary", ...finishButtonProps }]}
        />
      );
    }

    return (
      <>
        <Typography
          style={{ marginBottom: "4vh" }}
          variant="h6"
          align="center"
          id="passwordMngtType">
          {isResetMode
            ? "Type and confirm a secure new password for the account."
            : "Type and confirm a secure password for the account."}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit as any}
          maxWidth={550}
          width="100%"
          id="passwordMngtBox">
          <Box display="flex" alignItems="center">
            <Typography style={{ width: "40%" }} id="passwordMngtEnterPassTypo">
              Enter Password:
            </Typography>
            <PasswordField
              placeholder="Password"
              name="password"
              id="passwordMngtPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography style={{ width: "40%" }}>Confirm Password:</Typography>
            <PasswordField
              placeholder="Confirm Password"
              name="confirmPassword"
              id="passwordMngtConfirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            />
          </Box>

          {(touched.password && errors.password) ||
          (touched.password && errors.confirmPassword) ||
          submitError ? (
            <Box mt={2} id="passwordMngtErrorBox">
              <Typography color="error" variant="caption" id="passwordMngtErrorLabel">
                {touched.password && errors.password}
                {touched.confirmPassword && errors.confirmPassword}
              </Typography>
              <Typography align="center" color="error" id="passwordMngtSubmitError">
                {submitError}
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="center" mt="4vh" mx="auto" width={240}>
            <Button
              size="large"
              fullWidth
              type="submit"
              color="primary"
              id="passwordMngtResetOrSaveButton"
              variant="contained"
              disabled={!isValid || !touched.password || !touched.confirmPassword || loading}>
              {isResetMode ? "Reset" : "Save"}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" flex={1} alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default PasswordManagementView;
