import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { ICONS } from "@APP/assets";
import { useAppSelector, useAppDispatch } from "@APP/redux";
import { PageLayout, Message, CardPaymentButton } from "@APP/components";
import { setSelectedBank } from "@APP/redux/actions";
import { SCREEN_PATHS } from "@APP/navigation";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => {
  const LIST_ITEM_V_HEIGHT = 7;
  return {
    list: {
      maxHeight: 450,
      height: `${LIST_ITEM_V_HEIGHT * 5}vh`,
      overflowY: "auto",
      overflowX: "hidden",
      paddingTop: 0,
      border: `1px solid ${theme.palette.divider}`,
      "&::-webkit-scrollbar": {
        width: 10,
        backgroundColor: theme.palette.action.hover,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.action.active,
      },
    },
    listItem: {
      minHeight: `${LIST_ITEM_V_HEIGHT}vh`,
    },
    icon: {
      objectFit: "contain",
      width: "3rem",
      height: "3rem",
      marginRight: theme.spacing(3),
    },
  };
});

const BankSelectScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const styles = useStyles();

  const dispatch = useAppDispatch();
  const banksState = useAppSelector((state) => state.banks);
  const rtpState = useAppSelector((state) => state.rtp);
  const [bankSearchValue, setBankSearchValue] = useState("");

  const { error, banks, loading } = banksState;

  const filteredBanks = React.useMemo(() => {
    if (bankSearchValue && banks) {
      return banks.filter(
        (bank) =>
          bank.fullName.toLowerCase().includes(bankSearchValue.toLowerCase()) ||
          bank.shortName.toLowerCase().includes(bankSearchValue.toLowerCase()),
      );
    }
    return banks || [];
  }, [bankSearchValue, banks]);

  const handleResetClick = () => {
    history.push("/");
  };

  const handleBankSelect = (bankId: string) => {
    dispatch(setSelectedBank(bankId));
    history.push(SCREEN_PATHS.INITIATE_PAYMENT.replace(":bankId", bankId));
  };

  const renderMainContent = () => {
    if (error) {
      return (
        <Box display="flex" alignItems="center" flexDirection="column" pt="12vh" id="bankSelectBox">
          <Message
            type="error"
            title="Oops, something seems to have gone wrong"
            description="We were unable to retrieve the list of banks. Please try again later."
            buttons={[{ variant: "contained", color: "secondary", onClick: handleResetClick }]}
          />
        </Box>
      );
    }

    return (
      <>
        <img
          src={ICONS.BANK_ICON}
          style={{ width: "6.5vh" }}
          alt="Bank Selection"
          id="bankSelectImg"
        />
        <Box mt="2.5vh" mb="3vh">
          <label htmlFor="select-bank-search-input">
            <Typography color="textPrimary" align="center" id="bankSelectBankTypography">
              Please select the bank you would like to pay with:
            </Typography>
          </label>
        </Box>
        <Box width="110%" maxWidth="600px">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            onChange={(e) => setBankSearchValue(e.currentTarget.value)}
            placeholder="Enter bank name..."
            id="select-bank-search-input"
            value={bankSearchValue}
          />
          <Box p={2} maxHeight={18}>
            <Typography
              color="textPrimary"
              align="center"
              aria-atomic="true"
              role="status"
              id="bankSelectResultTypo">
              {filteredBanks.length} result(s) found.
            </Typography>
          </Box>
          <Box mb={3} />
          <List disablePadding className={styles.list} id="bankSelectListParent">
            {filteredBanks.length ? (
              filteredBanks?.map((bankItem, index) => (
                <ListItem
                  className={styles.listItem}
                  button
                  id={"bankSelectListItem" + index}
                  divider={index !== filteredBanks.length - 1}
                  key={bankItem.id}
                  onClick={() => handleBankSelect(bankItem.id)}>
                  {bankItem.logo ? (
                    <ListItemAvatar>
                      <img
                        className={styles.icon}
                        src={bankItem.logo}
                        id="bankSelectListItemBankLogo"
                        alt={`${bankItem.fullName} Logo`}
                      />
                    </ListItemAvatar>
                  ) : (
                    <ListItemAvatar>
                      <AccountBalanceIcon className={styles.icon} id="bankSelectAccountBalIcon" />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={bankItem.fullName}
                    id="bankSelectBankFullName"
                    primaryTypographyProps={{ color: "textPrimary" }}
                  />
                  <ListItemSecondaryAction>
                    <ChevronRightIcon color="action" id="bankSelectChevronRigthIcon" />
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <Typography paddingTop={3} align="center" id="bankSelectNoBankResult">
                There are no results for "<b>{bankSearchValue}</b>".
                <br />
                Please check your spelling or try different keywords.
              </Typography>
            )}
          </List>
          <Box mb={2} />
          {CONFIG?.FEATURE?.SQUARE && rtpState.rtp?.cardPaymentProvider ? (
            <CardPaymentButton />
          ) : null}
        </Box>
      </>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default BankSelectScreen;
