import { MaverickHostedFormPayload, MaverickPaymentPayload } from "@APP/types";

import request from "../request";

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

export const getMaverickPaymentFormUrl = async (body: MaverickHostedFormPayload) => {
  const res = await request<{ url: string }>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/payments/card-payments/hosted-form`,
    url: `${TEMP_API_BASE}/maverick/payments/card-payments/hosted-form`,
    data: body,
  });

  return res.data;
};

export const updateMaverickPaymentStatus = async (body: MaverickPaymentPayload) => {
  await request({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/maverick/payments/card-payments/hosted-form`,
    url: `${TEMP_API_BASE}/maverick/payments/card-payments/status`,
    data: body,
  });
};
