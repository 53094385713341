import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material";
import { Router } from "react-router-dom";

import { AlertProvider } from "@APP/hooks/useAlert";
import { GlobalStyles, themeOptions } from "@APP/styles";
import { AppRouter, routes, history } from "@APP/navigation";
import Config from "@APP/config";

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme({ ...themeOptions })}>
        {Config.SERVICES.CLARITY?.ID && (
          <Helmet>
            <script type="text/javascript">
              {`
                    (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${Config.SERVICES.CLARITY.ID}");
                  `}
            </script>
          </Helmet>
        )}
        <GlobalStyles />
        <AlertProvider>
          <Router history={history}>
            <AppRouter routes={routes} />
          </Router>
        </AlertProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
