import React from "react";

import AlertModal from "./AlertModal";

export enum ButtonTypes {
  decline = "decline",
  back = "back",
}

interface AlertButton {
  text?: string;
  type?: ButtonTypes;
  closable?: boolean;
  onClick?: (value?: string) => void;
}

interface AlertMessageType {
  value?: string;
  component?: React.ReactNode;
}
interface AlertOptions {
  title: string;
  message?: AlertMessageType;
  buttons?: AlertButton[];
}

const AlertContext = React.createContext<{
  showAlert: boolean;
  alertOptions?: AlertOptions | null;
  openAlert: (title: string, message?: AlertMessageType, buttons?: AlertButton[]) => void;
  closeAlert: () => void;
  setAlertOptions: (options: {
    title: string;
    message?: AlertMessageType;
    buttons?: AlertButton[];
  }) => void;
}>({
  showAlert: false,
  alertOptions: null,
  openAlert: () => null,
  closeAlert: () => null,
  setAlertOptions: () => null,
});

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertOptions, setAlertOptions] = React.useState<AlertOptions | null>(null);

  const openAlert = (title: string, message?: AlertMessageType, buttons?: AlertButton[]) => {
    setAlertOptions({ title, message, buttons });
    setShowAlert(true);
  };
  const closeAlert = () => {
    setAlertOptions(null);
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        alertOptions,
        openAlert,
        closeAlert,
        setAlertOptions,
      }}>
      <AlertModal />
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
