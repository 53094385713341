import { useEffect, useState } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useHistory } from "react-router-dom";

import { Message, PageLayout } from "@APP/components";
import { useAppDispatch, useAppSelector } from "@APP/redux";
import { formatCurrency } from "@APP/utils";
import { API } from "@APP/services";
import { clearRtpState } from "@APP/redux/actions";
import { MetroBankAccountDetails } from "@APP/types";

const MetroPaymentView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { senderData, rtpData } = useAppSelector((state) => state.rtp);
  const { selectedBank: bankId, banks } = useAppSelector((state) => state.banks);

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [bankAccountDetails, setBankAccountDetails] = useState<MetroBankAccountDetails | null>(
    null,
  );
  const [isError, setIsError] = useState(false);

  const bankName = banks?.length ? banks.find((bank) => bank.id === bankId)?.fullName : bankId;

  useEffect(() => {
    (async () => {
      try {
        const bankAccountData = await API.getMetroBankAccountDetails(senderData?.companyInfo?.id!);
        const url = await API.getMetroRedirectUrl(bankId!, {
          requestId: rtpData?.paymentRequestId,
          orgId: senderData?.companyInfo?.id,
        });

        setBankAccountDetails(bankAccountData);
        setRedirectUrl(url);
      } catch (e) {
        setIsError(true);
      }
    })();
  }, []);

  const handleNavigationOnDashboard = () => {
    history.push("/");
    dispatch(clearRtpState());
  };

  return (
    <PageLayout loading={!isError && (redirectUrl === null || bankAccountDetails === null)}>
      {isError ? (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong"
          description="Sorry, we are currently unable to take your payment, please try again later"
          buttons={[
            {
              text: "Okay",
              variant: "contained",
              color: "secondary",
              onClick: handleNavigationOnDashboard,
            },
          ]}
        />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" id="metroPaymentBox">
          <Box my={3}>
            <Typography align="center" component="p" variant="h6" id="metroPaymentInitiatePayTypo">
              Initiate a payment with {bankName}
            </Typography>
          </Box>
          <Box>
            <Typography id="metroPaymentLoginTypo">
              Please log in to your internet banking and pay using the below details:
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography id="metroPaymentBusinessName">
              <b>{senderData?.businessContact?.name ?? senderData?.name}</b> has requested
            </Typography>
          </Box>
          <Box my={0.5}>
            <Typography color="textSecondary" component="p" variant="h4" id="metroPaymentCurreny">
              {formatCurrency(rtpData?.paymentDetails.amount)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" mt={2} mb={5} flex={1}>
            <CopyRow title="Payee Name" id="payeeName" details={bankAccountDetails?.customerName} />
            <CopyRow
              title="Account Number"
              id="accountNumber"
              details={bankAccountDetails?.accountNumber}
            />
            <CopyRow title="Sort Code" id="sortCode" details={bankAccountDetails?.sortCode} />
            <CopyRow title="Reference" id="reference" details={rtpData?.payable?.reference} />
          </Box>
          <Box maxWidth={400} width="100%">
            <Button
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              id="metroPaymentGotoMetro"
              href={redirectUrl!}
              target="_blank"
              rel="noopener noreferrer">
              Go to Metro
            </Button>
          </Box>
        </Box>
      )}
    </PageLayout>
  );
};

const CopyRow = ({ title, details, id }: { title: string; details?: string; id?: string }) => {
  const theme = useTheme();
  const isSmSize = useMediaQuery(theme.breakpoints.down("md"));

  const [copied, setCopied] = useState(false);

  const onCopy = async () => {
    try {
      setCopied(true);

      await navigator.clipboard.writeText(details ? details : "");

      setTimeout(() => setCopied(false), 2000);
    } catch (err) {}
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 0.6fr"
      columnGap={isSmSize ? 2 : 5}
      alignItems="center">
      <Typography variant="subtitle1" fontWeight="bold" id={"copyRowTitle" + id}>
        {title}
      </Typography>
      <Typography id={"copyRowDetails" + id}>{details ? details : "-"}</Typography>
      <Button variant="text" onClick={onCopy} size="small" id={"copyRowButton" + id}>
        {copied ? (
          <>
            Copied
            <CheckRoundedIcon fontSize="small" />
          </>
        ) : (
          <>
            <ContentCopyRoundedIcon fontSize="small" />
            Copy
          </>
        )}
      </Button>
    </Box>
  );
};

export default MetroPaymentView;
