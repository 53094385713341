import { Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { useTranslation } from "react-i18next";
import { PAYMENT_PROVIDER } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  li: {
    color: theme.palette.text.primary,
    listStylePosition: "inside",
    margin: "2px 0",
  },
}));

const MoneyhubLegalInfo = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  if (CONFIG.PAYMENT_PROVIDER !== PAYMENT_PROVIDER.MONEYHUB) {
    return null;
  }

  return (
    <>
      {t("Common.MoneyhubText") ? (
        <Typography color="textPrimary" variant="caption" id={"moneyHubText"}>
          {t("Common.MoneyhubText")}
        </Typography>
      ) : null}
      <Box display="flex" flexDirection="column" width="100%" id={"moneyHubBox"}>
        <ul>
          <li className={styles.li}>
            <Link
              href={CONFIG.URLS.PAYMENT_PROVIDER.TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="caption"
              id={"moneyhubTermsAndConditionsLink"}
              color="textPrimary"
              underline="always">
              Terms and Conditions
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              href={CONFIG.URLS.PAYMENT_PROVIDER.PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="caption"
              id={"moneyhubPrivacyPolicyLink"}
              color="textPrimary"
              underline="always">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </Box>
    </>
  );
};

export default MoneyhubLegalInfo;
