import React from "react";
import { Switch } from "react-router-dom";

import { RouteConfig } from "./routes";
import AppRoute from "./AppRoute";
import AppModalRoute from "./AppModalRoute";

interface Props {
  routes: RouteConfig[];
}

/**
 * Renders the routes for given configuration
 * @param routes - array of routes configurations
 */
export const AppRouter = ({ routes }: Props) => {
  const regularRoutes = routes.filter((routeConfig) => !routeConfig.modal && routeConfig?.path);
  const modalRoutes = routes.filter((routeConfig) => !!routeConfig.modal && routeConfig?.path);

  return (
    <>
      <Switch>
        {regularRoutes.map((routeConfig: RouteConfig) => (
          <AppRoute key={routeConfig.path?.toString()} {...routeConfig} />
        ))}
      </Switch>
      {modalRoutes.map((routeConfig) => (
        <AppModalRoute key={routeConfig.path?.toString()} {...routeConfig} />
      ))}
    </>
  );
};

export default AppRouter;
