import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.75rem",
        },
        color: theme.palette.text.primary,
      },
      body: {
        background: theme.palette.background.gradient || theme.palette.background.default,
        height: "100%",
        width: "100%",
      },
      a: {
        cursor: "pointer",
      },
      /**
       * Prevents auto zoom into the input field on iPhone and iOS
       * when the font-size of an input element is less 16px.
       */
      ".MuiInputBase-input": {
        [theme.breakpoints.down("md")]: {
          fontSize: "1rem",
          padding: "1.2rem 1rem",
          "&::placeholder": {
            fontSize: "1.2rem",
          },
        },
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".hidden": {
        clip: "rect(1, 1, 1, 1)",
        clipPath: "inset(50%)",
        height: 1,
        width: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
      },
    },
  }),
);

/**
 * An empty component that applies global styles.
 */
const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
