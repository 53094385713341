import { useEffect, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { PageLayout, Message } from "@APP/components";
import { ICONS } from "@APP/assets";
import { API } from "@APP/services";
import CONFIG from "@APP/config";

interface RouteParams {
  token: string;
}
const ConfirmEmail: React.FC<RouteComponentProps> = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const IsMobileInvoiceIt = CONFIG.PRODUCT_NAME === "Invoice It" && isMobile;

  const params = match.params as RouteParams;
  const { token } = params;

  const searchParams = new URLSearchParams(document.location.search);

  const orgId = searchParams.get("orgId");

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { email } = orgId
            ? await API.verifyBusinessContactEmail(token, orgId)
            : await API.verifyEmail(token);
          setEmail(email);
        } catch (error: any) {
          setErrorMessage(
            error?.response?.data?.errorMessage ??
              "Sorry, something went wrong when verifying your email. Please try again later.",
          );
        }
        setLoading(false);
      })();
    }
  }, [token]);

  const renderMainContent = () => {
    if (errorMessage) {
      return (
        <Message
          type="error"
          title="Oops, something seems to have gone wrong."
          description={errorMessage}
        />
      );
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        ml={2}
        mr={2}
        id="confirmEmailBox">
        <img
          src={ICONS.CONFIRM_ICON}
          style={{ width: "6.5vh" }}
          alt="Success"
          id="confirmEmailImage"
        />
        <Box mt="8vh">
          <Typography variant="h6" id="confirmEmailThankYouTypo">
            Thank you!
          </Typography>
        </Box>
        <Box my="6vh">
          <Typography color="textSecondary" variant="h5" component="span" id="confirmEmailAddress">
            {email}
          </Typography>
        </Box>
        <Typography align="center" id="confirmEmailSuccessText">
          Your e-mail address has been successfully verified.
        </Typography>
        <Box mt={6} width="100%">
          {IsMobileInvoiceIt ? (
            <>
              <Button
                size="large"
                component="a"
                variant="contained"
                fullWidth
                id="confirmEmailGotoButton"
                color="primary"
                onClick={() => {
                  window.open(CONFIG.URLS.MOBILE?.LOGIN_URL);
                }}>
                Go to {CONFIG.PRODUCT_NAME}
              </Button>
              <Typography
                color="textSecondary"
                align="center"
                variant="h6"
                mt={6}
                id="confirmEmailOcmTypo">
                If you don’t have a mobile application, please{" "}
                <Link
                  href={CONFIG.URLS.OCM.ROOT_URL}
                  rel="noopener noreferrer"
                  underline="always"
                  id="confirmEmailClickHereButton"
                  color="inherit">
                  click here
                </Link>{" "}
                to open {CONFIG.PRODUCT_NAME} in your browser.
              </Typography>
            </>
          ) : (
            <Button
              size="large"
              component="a"
              variant="contained"
              fullWidth
              id="confirmEmailGotoButton"
              color="primary"
              href={CONFIG.URLS.OCM.ROOT_URL}>
              Go to {CONFIG.PRODUCT_NAME}
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" flex={1} alignItems="center" flexDirection="column">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default ConfirmEmail;
