import React from "react";
import parse from "html-react-parser";
import { Box, Typography, Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";

interface MessageButton extends ButtonProps {
  text?: string;
}

export interface Props {
  type?: "success" | "warning" | "info" | "error";
  title?: string;
  description?: string;
  buttons?: MessageButton[];
  showIcon?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode | string;
}

const useStyles = makeStyles((theme) => ({
  cardWarning: {
    color: theme.palette.text.primary,
  },
  cardError: {
    color: theme.palette.error.main,
  },
  cardSuccess: {
    color: theme.palette.text.primary,
  },
  cardInfo: {
    color: theme.palette.text.primary,
  },
  icon: {
    fontSize: "8.5vh",
    color: (props: { type: Props["type"] }) => theme.palette[props.type!].main,
  },
}));

const Message = ({
  type = "info",
  title,
  description,
  buttons,
  showIcon = true,
  style,
  children,
}: Props) => {
  const classes = useStyles({ type });
  return (
    <Box
      textAlign="center"
      id={"messageBox"}
      className={clsx({
        [classes.cardSuccess]: type === "success",
        [classes.cardWarning]: type === "warning",
        [classes.cardInfo]: type === "info",
        [classes.cardError]: type === "error",
      })}
      style={style}>
      <Box display="flex" id={"messageBox2"} flexDirection="column" alignItems="center" p={3}>
        {showIcon && (
          <Box mb="2.8vh">
            {type === "success" && <CheckIcon className={classes.icon} />}
            {type === "warning" && <WarningIcon className={classes.icon} />}
            {type === "info" && <InfoIcon className={classes.icon} />}
            {type === "error" && <ErrorOutlineIcon className={classes.icon} />}
          </Box>
        )}
        {!children && (
          <>
            {title && (
              <Box mb="2.8vh">
                <Typography color="inherit" variant="h6" id={"messageTitle"}>
                  {title}
                </Typography>
              </Box>
            )}
            {description && (
              <Typography color="inherit" gutterBottom id={"messageDescription"}>
                {parse(description)}
              </Typography>
            )}
          </>
        )}
        <Box>
          {typeof children === "string" ? (
            <Typography color="inherit">{children}</Typography>
          ) : (
            children
          )}
        </Box>
      </Box>
      {!!buttons?.length &&
        buttons.map(({ text = "Okay", variant = "text", color = "inherit", ...props }, index) => {
          return (
            <Button
              variant={variant}
              color={color}
              key={`${text}${index}`}
              {...props}
              id={"messageButton" + index}>
              {text}
            </Button>
          );
        })}
    </Box>
  );
};

export default Message;
