import { createReducer } from "deox";

import { setTokenLoading, setTokenSuccess, setTokenError } from "../actions";

export interface AuthState {
  loading: boolean;
  error: boolean;
  token: string | null;
}

const defaultState: AuthState = {
  token: null,
  loading: false,
  error: false,
};

const authReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setTokenLoading, (state, { payload }) => ({
    ...state,
    loading: payload,
    error: false,
  })),
  handleAction(setTokenSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    error: false,
    token: payload,
  })),
  handleAction(setTokenError, (state, { payload }) => ({
    ...state,
    token: null,
    loading: false,
    error: payload,
  })),
]);

export default authReducer;
