// import store from "@APP/redux";
import axios, { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/react";

// import { HEADERS } from "./constants";

type RequestOptions = {
  /**
   * Attaches auth token header to the request.
   */
  auth?: boolean;
  /**
   * Logs the request error remotely.
   */
  logError?: boolean;
};

const REQUEST_DEFAULT_OPTIONS: RequestOptions = {
  auth: true,
  logError: true,
};

/**
 * HTTP client wrapper around "axios" API.
 * @param config "axios" config: https://github.com/axios/axios#request-config
 * @param options
 *  "auth" - include auth header in the request;
 */
const request = async <T = any>(config: AxiosRequestConfig, options = REQUEST_DEFAULT_OPTIONS) => {
  options = { ...REQUEST_DEFAULT_OPTIONS, ...options };
  let headers = config.headers || {};

  /**
   * Disabled due to: https://bankifi.atlassian.net/browse/BK-46
   */
  // if (options.auth) {
  //   const bankifiToken = store.getState().auth.token;
  //   if (bankifiToken) headers = { ...headers, [HEADERS.AUTH_HEADER]: bankifiToken };
  // }

  if (options.logError) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(`API error, ${JSON.stringify(error?.response?.data ?? error)}`);
        Sentry.captureException(error);
        return Promise.reject(error);
      },
    );
  }

  return axios.request<T>({ ...config, headers });
};

export default request;
