import { Action } from "redux";
import { createActionCreator } from "deox";
import { ThunkDispatch } from "redux-thunk";

import CONFIG from "@APP/config";
import { AppState } from "@APP/redux";
import { RequestResponse, RTP, SenderDataResponse } from "@APP/types";
import { API } from "@APP/services";

export const setRtpSuccess = createActionCreator(
  "@rtp/SET_RTP_SUCCESS",
  (resolve) => (rtpData: { senderData: SenderDataResponse; rtpData: RequestResponse; rtp: RTP }) =>
    resolve(rtpData),
);

export const clearRtpState = createActionCreator(
  "@rtp/CLEAR_RTP_STATE",
  (resolve) => () => resolve(),
);

export const getRtpDetails =
  (senderDataPayload: string, rtpDataPayload: string) =>
  async (dispatch: ThunkDispatch<AppState, void, Action>): Promise<AppState["rtp"]> => {
    const senderData = await API.getSenderData(senderDataPayload);
    const rtpDecoded = await API.decodeRTP(rtpDataPayload);
    const rtpData = (await API.getRTP(rtpDecoded.paymentRequestId)).data[0];

    dispatch(
      setRtpSuccess({
        senderData,
        rtpData: rtpDecoded,
        rtp: rtpData,
      }),
    );

    if (["test", "development"].includes(CONFIG.APP_ENV)) {
      console.info("RTP:", { senderData, rtpDecoded, rtpData });
    }

    return { senderData, rtpData: rtpDecoded, rtp: rtpData };
  };
