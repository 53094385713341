import { Box, Typography, Button, Avatar } from "@mui/material";

import { history, SCREEN_PATHS } from "@APP/navigation";

import { DIGITAL_WALLETS_LOGOS } from "@APP/assets";

export const CardPaymentButton = () => {
  return (
    <>
      <Box width="100%" mb={2}>
        <Typography align="center">or</Typography>
      </Box>
      <Box width="100%" mb={2}>
        <Button
          size="small"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ justifyContent: "space-evenly" }}
          startIcon={
            <Avatar
              variant="square"
              sx={{ width: "44px", height: "28px" }}
              src={DIGITAL_WALLETS_LOGOS.APPLE_PAY_MARK}
            />
          }
          endIcon={
            <Avatar
              variant="square"
              sx={{ width: "50px", height: "32px" }}
              src={DIGITAL_WALLETS_LOGOS.GOOGLE_PAY_MARK}
            />
          }
          onClick={() => history.replace(SCREEN_PATHS.CARD_PAYMENT)}>
          Pay with Card
        </Button>
      </Box>
    </>
  );
};

export default CardPaymentButton;
