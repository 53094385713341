/**
 * Absoulte paths for main app screens.
 */
export enum SCREEN_PATHS {
  DEFAULT = "*",
  CONFIRM_EMAIL = "/verify/:token",
  RESET_PASSWORD = "/reset-password",
  SETUP_PASSWORD = "/setup-password",
  LANDING = "/landing/:senderDataKey/:rtpKey",
  DECLINE_RTP = "/declineRequest/:requestId",
  BANK_SELECT = "/bankselect",
  INITIATE_PAYMENT = "/consent/:bankId",
  PAYMENT_COMPLETE = "/paymentcomplete",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  PRIVACY_NOTICE = "/privacy-notice",
  MAVERICK_PAYMENT = "/maverick-payment",
  METRO_PAYMENT = "/metro-payment",
  CARD_PAYMENT = "/card-payment",
}

/**
 * Relative paths for modal views.
 */
export enum MODAL_PATHS {
  THEME = "/theme",
}

const PATHS = {
  SCREEN_PATHS,
  MODAL_PATHS,
};

export default PATHS;
