import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { IMAGES } from "@APP/assets";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "auto",
    height: "10vh",
    objectFit: "contain",
  },
}));

const Header = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box component="header" display="flex" justifyContent="center" py="4vh" id={"pageHeaderBox"}>
      <img
        src={IMAGES.APP_LOGO}
        className={styles.logo}
        alt={t("ProductName")}
        id="pageHeaderImage"
      />
      <Typography className="hidden" component="h1" id="pageHeaderProductName">
        {t("ProductName")}
      </Typography>
    </Box>
  );
};

export default Header;
