import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { AppState } from "@APP/redux";
import { RouteConfig } from "./routes";
import { RouteProtector } from "./protectors";

interface Props extends RouteConfig {
  appState: AppState;
}

/**
 * Extended Route component.
 * Handles route protection for a give `protector` property.
 * Performs the appropriate redirect based on the "route protection" result, if necessary.
 */
const AppRoute = ({
  appState,
  path,
  redirect,
  routes,
  component: Component,
  children,
  protector,
}: Props) => {
  let protectorRenderResult: JSX.Element | null = null;

  /**
   * Handles route protection.
   * Specifies the appropriate redirect based on the invocation of "route protector" function.
   * @param protectorFn - RouteProtector function.
   * @returns Redirect component or null.
   */
  const handleRouteProtector = (protectorFn: RouteProtector) => {
    if (typeof protectorFn === "function") {
      const protectionResult = protectorFn(appState, path);
      if (typeof protectionResult === "string") {
        return <Redirect push={false} to={protectionResult} />;
      }
    }
    return null;
  };

  if (protector) {
    if (Array.isArray(protector)) {
      protector.some((protectorFn) => {
        protectorRenderResult = handleRouteProtector(protectorFn);
        return !!protectorRenderResult;
      });
    } else {
      protectorRenderResult = handleRouteProtector(protector);
    }
  }

  return (
    protectorRenderResult || (
      <Route
        path={path}
        children={children}
        render={(props) =>
          redirect ? (
            <Redirect push={false} to={redirect} />
          ) : (
            Component && <Component {...props} routes={routes} />
          )
        }
      />
    )
  );
};

const mapStateToProps = (appState: AppState) => ({ appState });

export default connect(mapStateToProps)(AppRoute);
