import React from "react";
import { Box, Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import DownloadIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { format } from "date-fns";

import { ERP_ID, ATTACHEMENTS } from "@APP/types";
import { AppState } from "@APP/redux";
import { formatCurrency } from "@APP/utils";

interface Props {
  rtpState: AppState["rtp"];
}

const RTPSummary: React.FC<Props> = ({ rtpState }) => {
  const { rtp, rtpData, senderData } = rtpState;

  const { entityDetails, lineItems, reference } = rtpData?.payable || {};

  const isInternal = entityDetails?.source === ERP_ID.INTERNAL;
  const isNative = rtp?.receivable.features?.includes("Native");
  const invoiceLink = rtpData?.payable?.attachments.find(
    (item) => item.name === ATTACHEMENTS.RECEIVABLE_PDF && item.contentType === "application/pdf",
  )?.uri;

  // Download button should be displayed in case of external invoice or internal native invoice:
  const shouldDisplayDownloadLink = invoiceLink && (!isInternal || isNative);

  return (
    <Box textAlign="center" id="rtpSummeryBox">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography id="rtpSummeryTypogrophy">
          <b>{senderData?.businessContact?.name ?? senderData?.name}</b> has requested
        </Typography>
      </Box>
      <Box my={0.5}>
        <Typography color="textSecondary" component="p" variant="h4" id="rtpSummeryAmount">
          {formatCurrency(rtpData?.paymentDetails.amount)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography gutterBottom id="rtpSummeryInvoiceTypo">
          {isInternal ? "for" : "for invoice"}&nbsp;
        </Typography>
        <Typography component="p" variant="subtitle1" gutterBottom id="rtpSummeryRef">
          {isInternal ? lineItems?.[0].description : reference}
        </Typography>
      </Box>
      {rtp?.standingOrder && (
        <Box mt={2} mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography gutterBottom id="rtpSummeryPayment">
            Payment to be taken in the below instalments
          </Typography>
          <Box
            mt={1}
            maxWidth={320}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center">
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryPaymentLabel">
                Payment Amount
              </Typography>
              <Typography gutterBottom id="rtpSummeryFirstPaymentAmt">
                {formatCurrency(rtp.standingOrder.firstPaymentAmount)}
              </Typography>
            </Box>
            {rtp.standingOrder.finalPaymentAmount && (
              <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" gutterBottom id="rtpSummeryFinalPaymentLabel">
                  Final Payment
                </Typography>
                <Typography gutterBottom id="rtpSummeryFinalPaymentAmy">
                  {formatCurrency(rtp.standingOrder.finalPaymentAmount)}
                </Typography>
              </Box>
            )}
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryFrequencyLabel">
                Frequency
              </Typography>
              <Typography gutterBottom id="rtpSummeryFreq">
                {rtp.standingOrder.frequency}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryOccurencesLabel">
                Occurrences
              </Typography>
              <Typography gutterBottom id="rtpSummeryNoOfPayments">
                {rtp.standingOrder.numberOfPayments}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" gutterBottom id="rtpSummeryFirstPayDateLabel">
                First payment date
              </Typography>
              <Typography gutterBottom id="rtpSummeryFirstPaymentDate">
                {format(new Date(rtp.standingOrder.firstPaymentDate!), "yyyy-MM-dd")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
        <Button
          size="small"
          href={`mailto:${senderData?.businessContact?.email ?? senderData?.companyInfo.email}`}
          target="_blank"
          id="rtpSummeryContactButton"
          rel="noopener noreferrer"
          startIcon={<EmailIcon />}>
          Contact {senderData?.businessContact?.name ?? senderData?.name}
        </Button>
        {shouldDisplayDownloadLink && (
          <Button
            size="small"
            href={invoiceLink}
            target="_blank"
            rel="noopener noreferrer"
            id="rtpSummeryDownloadInvoiceButton"
            download
            startIcon={<DownloadIcon />}>
            Download Invoice
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RTPSummary;
