import { createStore, applyMiddleware, compose, Action } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk, { ThunkDispatch } from "redux-thunk";

import rootReducer from "../reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [],
  whitelist: ["auth", "rtp", "banks"], // list of reducers which should be persited
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducer>;

type AppDispatch = typeof store.dispatch;

const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();
const useThunkDispatch = () => useDispatch<ThunkDispatch<AppState, void, Action>>();

export { persistor, useAppSelector, useAppDispatch, useThunkDispatch };

export default store;
