import { createReducer } from "deox";

import { RequestResponse, RTP, SenderDataResponse } from "@APP/types";

import { clearRtpState, setRtpSuccess } from "../actions";

export interface RtpState {
  senderData: SenderDataResponse | null;
  rtpData: RequestResponse | null;
  rtp: RTP | null;
}

const defaultState: RtpState = {
  rtpData: null,
  senderData: null,
  rtp: null,
};

const rtpReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setRtpSuccess, (state, { payload }) => ({
    ...state,
    rtpData: payload.rtpData,
    senderData: payload.senderData,
    rtp: payload.rtp,
  })),
  handleAction(clearRtpState, (state) => ({
    ...state,
    ...defaultState,
  })),
]);

export default rtpReducer;
