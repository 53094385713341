import { ThemeOptions, SimplePaletteColorOptions } from "@mui/material";
import palette from "./palette";

// Due to issues with runtime type checking causing errors when using palette.primary.main / palette.secondary.main from MUI type, had to define again internally.
const paletteSecondary = palette.secondary as SimplePaletteColorOptions;

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 140,
        boxShadow: "none",
      },
      contained: {
        padding: "0.75rem 1.75rem",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
      text: {
        color: palette.text?.primary,
      },
      sizeLarge: {
        fontSize: "1rem",
        padding: "0.75rem 1.75rem",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#99A9BF",
      },
      input: {
        padding: "14px 14px",
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
        borderRadius: 6,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&:focus-visible, &:focus-visible:hover": {
          outline: `2px solid ${paletteSecondary.main}`,
          outlineOffset: "-2px",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 20,
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: "#FF844B",
      },
    },
  },
};

export default components;
