import { PAYMENT_PROVIDER } from "@APP/types";
import CONFIG from "./config.json";

interface AppConfig {
  PRODUCT_NAME: string;
  APP_ENV: string;
  VERSION: string;
  API: {
    ROOT_URL: string;
    REDIRECT_PATH_PAYMENT_COMPLETE: string;
    VERSION: string;
    CUSTODIANS_PROVIDER_PATH: string;
  };
  URLS: {
    APP_STORE_URL: string;
    PLAY_STORE_URL: string;
    TERMS_AND_CONDITIONS_URL: string;
    PAYMENT_PROVIDER: {
      TERMS_AND_CONDITIONS_URL: string;
      PRIVACY_POLICY_URL: string;
    };
    OCM: {
      ROOT_URL: string;
      LOGIN_URL: string;
      REGISTRATION_URL?: string;
    };
    MOBILE?: {
      LOGIN_URL: string;
    };
  };
  ASSOCIATION: {
    CO_OPERATIVE_BANK: boolean;
  };
  HOSTED_PAGES: {
    TERMS_AND_CONDITIONS: boolean;
    PRIVACY_NOTICE: boolean;
  };
  FEATURE?: {
    SQUARE: boolean;
  };
  SERVICES: {
    SENTRY?: {
      ORG: string;
      PROJECT: string;
      DSN: string;
    };
    CLARITY?: {
      ID?: string;
    };
    SQUARE?: {
      WEB_SDK_CDN_URL: string;
      APP_ID: string;
      CURRENCY: string;
    };
  };
  PAYMENT_PROVIDER: PAYMENT_PROVIDER;
}

if (CONFIG.APP_ENV !== "production" || process.env.NODE_ENV !== "production") {
  console.info("App сonfiguration:", CONFIG);
} else {
  console.info(`v${CONFIG.VERSION}`);
}

export default CONFIG as AppConfig;
