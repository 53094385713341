import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import store, { persistor } from "@APP/redux";
import App from "@APP/App";
import CONFIG from "@APP/config";
import { i18n } from "@APP/services";

import reportWebVitals from "./reportWebVitals";

i18n.init();

if (CONFIG.SERVICES.SENTRY && !["test", "development"].includes(process.env.NODE_ENV)) {
  Sentry.init({ dsn: CONFIG.SERVICES.SENTRY.DSN });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
