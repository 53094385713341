import { Action } from "redux";
import { createActionCreator } from "deox";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "@APP/redux";
import { API } from "@APP/services";
import { BanksResponse } from "@APP/types";

export const setBanksLoading = createActionCreator(
  "@banks/SET_BANK_LOADING",
  (resolve) => (loading: boolean) => resolve(loading),
);

export const setBanksSuccess = createActionCreator(
  "@banks/SET_BANK_SUCCESS",
  (resolve) => (banksData: BanksResponse[]) => resolve(banksData),
);

export const setBanksError = createActionCreator(
  "@banks/SET_BANK_ERROR",
  (resolve) => (isError: boolean) => resolve(isError),
);

export const setSelectedBank = createActionCreator(
  "@banks/SET_SELECTED_BANK",
  (resolve) => (bankId: string) => resolve(bankId),
);

export const loadBanks = () => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
  dispatch(setBanksLoading(true));
  try {
    const banks = await API.getBanks();
    dispatch(setBanksSuccess(banks));
  } catch (err) {
    dispatch(setBanksError(true));
  }
};
