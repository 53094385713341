import * as Yup from "yup";

const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHAR_REGEX = /[!@#$%^&*?,.:;(_+=)-]/;

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must include at least 8 characters")
    .matches(UPPERCASE_REGEX, "Password must include an uppercase letter")
    .matches(LOWERCASE_REGEX, "Password must include a lowercase letter")
    .matches(NUMBER_REGEX, "Password must include a number")
    .matches(SPECIAL_CHAR_REGEX, "Password must include a special character (!@#$%^&*?,.:;()-_+=)"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords don't match"),
});
