import { Fragment } from "react";
import { FormikConfig } from "formik";

import { passwordValidationSchema } from "@APP/utils";

export const validate: FormikConfig<{
  password: string;
  confirmPassword: string;
}>["validate"] = (values: any) =>
  passwordValidationSchema
    .validate(values, {
      abortEarly: false,
      strict: false,
    })
    .then(() => ({}))
    .catch(({ inner }: any) =>
      inner.reduce((memo: any, { path, message }: any, idx: number) => {
        return {
          ...memo,
          [path]: (memo[path] || []).concat(
            <Fragment key={path + idx}>
              <span style={{ display: "block" }} id="resetPasswordSchemaMessage">
                {message}
              </span>
            </Fragment>,
          ),
        };
      }, {}),
    );
