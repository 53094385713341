import { BanksResponse } from "@APP/types";
import { createReducer } from "deox";

import { setBanksLoading, setBanksError, setBanksSuccess, setSelectedBank } from "../actions";

export interface BanksState {
  loading: boolean;
  error: boolean;
  banks: BanksResponse[] | null;
  selectedBank: string | null;
}

const defaultState: BanksState = {
  loading: true,
  error: false,
  banks: null,
  selectedBank: null,
};

const banksReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setBanksLoading, (state, { payload }) => ({
    ...state,
    loading: payload,
    error: false,
  })),
  handleAction(setBanksSuccess, (state, { payload }) => ({
    ...state,
    banks: payload,
    error: false,
    loading: false,
  })),
  handleAction(setBanksError, (state, { payload }) => ({
    ...state,
    banks: null,
    loading: false,
    error: payload,
  })),
  handleAction(setSelectedBank, (state, { payload }) => ({
    ...state,
    selectedBank: payload,
  })),
]);

export default banksReducer;
