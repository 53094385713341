import { useEffect, useState } from "react";
import { Helmet, HelmetTags } from "react-helmet";
import { Payments, Card, Square, ChargeVerifyBuyerDetails } from "@square/web-sdk";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { useAlert } from "@APP/hooks";
import { PageLayout } from "@APP/components";
import { useAppSelector } from "@APP/redux";
import { formatCurrency } from "@APP/utils";
import { history, SCREEN_PATHS } from "@APP/navigation";
import { PAYMENT_PROVIDER, CardPaymentPayload } from "@APP/types";
import { getConsentId, confirmCardPayment } from "@APP/services/api";

import {
  getModalCommonButton,
  getModalMessageCardPayments,
} from "../DeclineRTPScreen/getModalContent";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paymentForm: {
    "& button": {
      marginBottom: 30,
    },
  },
}));

export const CardPayment = () => {
  const classes = useStyles();
  const { alert } = useAlert();

  const [squareSdk, setSquareSdk] = useState<Square>();
  const [card, setCard] = useState<Card>();
  const [paymentSquare, setPaymentSquare] = useState<Payments>();
  const [payButtonDisabled, setPayButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [consentId, setConsentId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();

  const rtpData = useAppSelector((state) => state.rtp);

  useEffect(() => {
    initialisePaymentOptions();
  }, [squareSdk, locationId]);

  useEffect(() => {
    fetchConsentAndLocationId();
  }, []);

  const handleScriptLoading = ({ scriptTags }: HelmetTags) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];

      scriptTag.onload = () => {
        setSquareSdk(window.Square);
      };
    }
  };

  const cardPaymentPayload: CardPaymentPayload = {
    amount: {
      amount: rtpData.rtp?.amount.amount!,
      //  currency: rtpData.rtp?.amount.currency!, for testing purposes the currency must remain USD due to the nature of the account being used for testing.
      currency: CONFIG.SERVICES.SQUARE?.CURRENCY || "USD",
    },
    orgId: rtpData?.senderData?.companyInfo?.id,
    customerId: rtpData.rtpData?.payable.supplierContact.email!,
    rtpId: rtpData.rtp?.id!,
    invoiceDetails: {
      source: rtpData.rtpData?.payable.entityDetails.source!,
      externalId: rtpData.rtpData?.payable.entityDetails.externalId!,
    },
  };

  const fetchConsentAndLocationId = async () => {
    if (rtpData.rtpData?.payable.supplierContact.email && rtpData?.senderData?.companyInfo?.id) {
      const { consentId } = await getConsentId(
        rtpData.rtpData?.payable.supplierContact.email,
        rtpData?.senderData?.companyInfo?.id,
      );
      const [locationId] = consentId.split("::");
      setConsentId(consentId);
      setLocationId(locationId);
    }
  };

  const initialisePaymentOptions = async () => {
    if (squareSdk && CONFIG.SERVICES.SQUARE?.APP_ID && locationId) {
      const payments: Payments = squareSdk.payments(CONFIG.SERVICES.SQUARE?.APP_ID, locationId);
      try {
        const card = await payments.card();
        await card.attach("#card-container");
        setCard(card);
        setPaymentSquare(payments);
        await initialiseDigitalWalletPaymentRequest(payments);
      } catch (e: any) {
        const errorData = e?.response?.data;
        alert("", getModalMessageCardPayments(errorData), getModalCommonButton());
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCardPayment = async () => {
    try {
      setPayButtonDisabled(true);
      const { token } = await card!.tokenize();
      if (token) {
        const verificationDetails: ChargeVerifyBuyerDetails = {
          amount: cardPaymentPayload.amount.amount,
          currencyCode: cardPaymentPayload.amount.currency,
          intent: "CHARGE",
          billingContact: {
            email: cardPaymentPayload.customerId,
          },
        };
        let verificationToken = await paymentSquare!.verifyBuyer(token, verificationDetails);
        if (rtpData.rtpData?.payable && consentId && token && verificationToken) {
          const paymentConfirmation = await confirmCardPayment(
            consentId,
            token,
            cardPaymentPayload,
            verificationToken.token,
          );
          if (paymentConfirmation) handlePaymentSuccessNavigate();
        }
      }
    } catch (e: any) {
      const errorData = e?.response?.data;
      alert("", getModalMessageCardPayments(errorData), getModalCommonButton());
    } finally {
      setPayButtonDisabled(false);
    }
  };

  // Digital Wallets

  const initialiseDigitalWalletPaymentRequest = async (payments: Payments) => {
    try {
      const paymentRequest = payments.paymentRequest({
        countryCode: CONFIG.PAYMENT_PROVIDER === PAYMENT_PROVIDER.MAVERICK ? "US" : "GB",
        currencyCode: rtpData.rtp?.amount.currency!,
        total: {
          amount: rtpData.rtp?.amount.amount!,
          label: "Total",
        },
      });

      const googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach("#google-pay-button", { buttonType: "short", buttonSizeMode: "fill" });
      const googlePayButton = document.getElementById("google-pay-button");

      if (googlePayButton)
        googlePayButton.onclick = async () => {
          const { token } = await googlePay.tokenize();
          if (rtpData.rtpData?.payable && consentId && token) {
            const paymentConfirmation = await confirmCardPayment(
              consentId,
              token,
              cardPaymentPayload,
            );
            if (paymentConfirmation) handlePaymentSuccessNavigate();
          }
        };

      const applePay = await payments.applePay(paymentRequest);
      // No need to attach button for Apple Pay.
      const applePayButton = document.getElementById("apple-pay-button");

      if (applePayButton)
        applePayButton.onclick = async () => {
          const { token } = await applePay.tokenize();
          if (rtpData.rtpData?.payable && consentId && token) {
            const paymentConfirmation = await confirmCardPayment(
              consentId,
              token,
              cardPaymentPayload,
            );
            if (paymentConfirmation) handlePaymentSuccessNavigate();
          }
        };
    } catch (error) {
      console.log("Digital Wallet error: ", error);
    }
  };

  const handlePaymentSuccessNavigate = () => {
    history.replace(SCREEN_PATHS.PAYMENT_COMPLETE, { cardPaymentFlow: true });
  };

  return (
    <PageLayout loading={isLoading}>
      <>
        <Helmet
          script={[{ src: CONFIG.SERVICES?.SQUARE?.WEB_SDK_CDN_URL }]}
          onChangeClientState={(_, tags) => handleScriptLoading(tags)}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box className={classes.container} id="test">
            <Typography align="center" component="p" variant="h6" sx={{ py: 3 }}>
              {`Initiate a card payment of ${formatCurrency({
                amount: rtpData.rtp?.amount.amount,
                currency: rtpData.rtp?.amount.currency,
              })} to ${rtpData.senderData?.businessContact?.name ?? rtpData.senderData?.name} `}
            </Typography>
            <form id="payment-form" className={classes.paymentForm}>
              <div id="card-container"></div>
              <Button
                id="card-button"
                disabled={payButtonDisabled}
                onClick={handleCardPayment}
                variant="contained"
                fullWidth>
                {payButtonDisabled ? <CircularProgress color="secondary" /> : "Pay"}
              </Button>
              <div id="google-pay-button"></div>
              <div id="apple-pay-button"></div>
            </form>
          </Box>
        </div>
      </>
    </PageLayout>
  );
};

export default CardPayment;
