import CONFIG from "@APP/config";
import { BusinessLevelContactStatus } from "@APP/types";

import { DEFAULT_USER } from "./../constants";
import request from "../request";

export const login = async () => {
  const { data } = await request<{ token: string }>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/login`,
      data: DEFAULT_USER,
    },
    {
      auth: false,
    },
  );
  return data.token;
};

export const verifyBusinessContactEmail = async (email: string, orgId: string) => {
  const res = await request<BusinessLevelContactStatus>(
    {
      method: "GET",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/organisations/${orgId}/contact/verify/${email}`,
    },
    {
      auth: false,
    },
  );
  return res.data;
};
