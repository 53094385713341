import { RTP, RTPUpdateResponse } from "@APP/types";
// import CONFIG from "@APP/config";
import request from "../request";
import { ERP_ID } from "@APP/types";

export const DEFAULT_ERP_ID: ERP_ID = ERP_ID.INTERNAL;

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

export const decodeRTP = async (payload: string) => {
  const body = {
    payload,
  };
  const res = await request<any>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/payment-request-decode`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/payment-request-decode`,
    data: body,
  });
  return res.data;
};

export const getSenderData = async (payload: string) => {
  const body = {
    payload,
  };
  const res = await request<any>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/sender-data`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/sender-data`,
    data: body,
  });
  return res.data;
};

export const getRTP = async (requestId: string) => {
  const res = await request<{ data: RTP[] }>({
    method: "GET",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/payment-requests?requestId=${requestId}`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/payment-requests?requestId=${requestId}`,
  });
  return res.data;
};

export const blockRTPs = async (payerId: string, erpId: ERP_ID = DEFAULT_ERP_ID) => {
  const res = await request<any>({
    method: "PUT",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/erp/${erpId}/customers/${payerId}/block`,
    url: `${TEMP_API_BASE}/erp/${erpId}/customers/${payerId}/block`,
  });
  return res.data;
};

/**
 * Updates RTP status to InProgress.
 * @param requestId - RTP ID
 */
export const initiateRTP = async (requestId: string) => {
  const res = await request<RTPUpdateResponse>({
    method: "PUT",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/${requestId}/payment-initiated`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/${requestId}/payment-initiated`,
  });
  return res.data;
};

/**
 * Updates RTP status to Declined.
 * @param requestId - RTP ID
 */
export const declineRTP = async (requestId: string) => {
  const res = await request<RTPUpdateResponse>({
    method: "PUT",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/request-to-pay/${requestId}/decline`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/${requestId}/decline`,
  });
  return res.data;
};
