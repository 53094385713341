import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { Message, PageLayout } from "@APP/components";
import { API } from "@APP/services";
import { useAppDispatch, useAppSelector } from "@APP/redux";
import { clearRtpState } from "@APP/redux/actions";
import CONFIG from "@APP/config";

const useStyles = makeStyles(() => ({
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

const MaverickPaymentView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const rtpState = useAppSelector((state) => state.rtp);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const handleNavigationOnDashboard = () => {
    history.push("/");
    dispatch(clearRtpState());
  };

  useEffect(() => {
    API.getMaverickPaymentFormUrl({
      redirectUrl: window.location.origin + CONFIG.API.REDIRECT_PATH_PAYMENT_COMPLETE,
      customerId: rtpState.rtpData?.payable.supplierContact.email!,
      rtpId: rtpState.rtp?.id!,
      amount: rtpState.rtpData?.paymentDetails.amount!,
      entityDetails: rtpState.rtpData?.payable.entityDetails!,
    })
      .then((res) => setPaymentUrl(res.url))
      .catch(() => setIsError(true));
  }, []);

  return (
    <PageLayout loading={isLoading && !isError && paymentUrl === null}>
      <Box flex={1}>
        {isError ? (
          <Message
            type="error"
            title="Oops, something seems to have gone wrong"
            description="Sorry, we are currently unable to take your payment, please try again later"
            buttons={[
              {
                text: "Okay",
                variant: "contained",
                color: "secondary",
                onClick: handleNavigationOnDashboard,
              },
            ]}
          />
        ) : (
          <iframe
            title="Maverick Payment Widget"
            src={paymentUrl as string}
            id="maverickPaymentWidgetIframe"
            className={classes.iframe}
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation-by-user-activation"
            onLoad={() => setIsLoading(false)}
          />
        )}
      </Box>
    </PageLayout>
  );
};

export default MaverickPaymentView;
