import { combineReducers } from "redux";
import authReducer from "./auth";
import banksReducer from "./banks";
import rtpReducer from "./rtp";

const rootReducer = combineReducers({
  auth: authReducer,
  rtp: rtpReducer,
  banks: banksReducer,
});

export { rootReducer };

export default rootReducer;
